import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Pagination from '../../components/Pagination/Pagination';
import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import Toast from '../../components/Toast/Toast';
import { getUsers } from '../../services/UsersService';
import UserRow from './UserRow';
import NewUserButton from './NewUserButton';
import UserModal from './UserModal/UserModal';


function Users() {

    const defaultLocation = useLocation();

    const { nome } = useParams();

    const [search, setSearch] = useState(nome || '');

    const [notification, setNotification] = useState({ type: '', text: '' });

    const history = useHistory();

    const [users, setUsers] = useState([]);

    const [editUser, setEditUser] = useState({});



    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }


    const [count, setCount] = useState(0);

    const [page, setPage] = useState(parseInt(getPage()));

    function getUsersCall(token) {
        getUsers(search, page || 1, token)
            .then(result => {
                console.log(page);
                console.log(result.rows);

                setUsers(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }
    // paginacao
    useEffect(() => {
        const token = localStorage.getItem('token');

        getUsersCall(token);
    }, [page, search])

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history])

    function onSearchChange(event) {
        setSearch(event.target.value);
        console.log(event.target.value);
    }

    // tratamento do formulario

    function onNewUserClick(event) {
        setEditUser(
            {
                nome: '',
                descricao: '',
                ativo: false
            })
    }

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        console.log('OnEditClick...');
        console.log(id);
        console.log(editUser)
        setEditUser(users.find(u => u.id == id));
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                {/* Cabeçalho da página */}
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        {/* <h1 className="h4">Users...</h1> */}
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent mb-0">
                                <li className="breadcrumb-item">
                                    <a href="#">
                                        <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                    </a>
                                </li>
                                {/* <li className="breadcrumb-item"><a href="#">Category</a></li> */}
                                <li className="breadcrumb-item active" aria-current="page">Usuários</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewUserButton onClick={onNewUserClick} />
                        </div>
                        <div className="ms-2 ms-lg-3">
                            {/* Campo para consulta */}

                            <SearchSymbol placeholder="User" onChange={onSearchChange} />
                        </div>
                    </div>
                </div>

                {/* <h1 className="h4">3 - Pontos - Montar tela..</h1> */}

                <div className="row">
                    <div className="col-12">
                        {/* Corpo da pagina */}
                        {/* INICIO DA TABELA */}
                        <div className="card card-body border-0 shadow table-wrapper table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th className="border-gray-200">View</th>
                                        <th className="border-gray-200">Código</th>
                                        <th className="border-gray-200">Nome</th>
                                        <th className="border-gray-200">Email</th>
                                        <th className="border-gray-200">Atualizado</th>
                                        <th className="border-gray-200">Ativo</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map(users => (
                                            <UserRow key={users.id} data={users} onEditClick={onEditClick} />
                                        ))
                                    }
                                </tbody>
                            </table>
                            <Pagination count={count} />
                        </div>
                        {/* FINAL DA TABELA */}


                    </div>
                </div>
                {/* Rodape da página */}
                <Footer />
            </main>
            <UserModal data={editUser} onSubmit={onModalSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    );
}

export default Users;
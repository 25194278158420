import React from 'react';

/**
 * props:
 * - name
 * - Titulo
 * - Campo
 * - placeholder
 * - onChange
 * - size
 */
function TextEdit(props) {
    return (

        // <div className="col-sm-3 mb-3">
        <div className={"col-sm-" +props.size +" mb-3"}>
            <div className="form-group">
                <label htmlFor={props.name}>{props.titulo}</label>
                <input className="form-control" id={props.name} name={props.name} type="text" placeholder={props.placeholder} defaultValue={props.field} onChange={props.onChange} />
            </div>
        </div>

    )
}

export default TextEdit;
import React, { useState, useEffect, useRef } from 'react';


/**
 * props:
 * - data
 */
function SymbolModal(props) {

    const btnClose = useRef('');
    const [error, setError] = useState('');
    const [device, setDevice] = useState({
        alias: '',
        nome: 'beacon'
    });

    useEffect(() => {
        if (!props.data) return;
        setDevice(props.data);
    }, [props.data])
    console.log("2 - props data !!!");
    console.log(props.data);
    console.log(props.data.alias);
    return (
        <div className="modal fade" id="modalSymbol" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true" data-bs-backdrop="static" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">Dispositivo</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3">
                            <div className="form-group mb-4">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-group mb-4">
                                            <label htmlFor="symbol">Id.</label>
                                            <div className="input-group">
                                                <input className="form-control" id="symbol" type="text" placeholder="BTCUSD" defaultValue={device.id} required disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Base Precision: </b>{device.alias}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Quote Precision: </b>{device.ativo}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Min. Notional: </b> {device.id}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Min. Lot Size: </b> {device.nome}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Tick Size: </b>00
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Step Size: </b>00
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        error
                            ? <div className="alert alert-danger">{error}</div>
                            : <React.Fragment></React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SymbolModal;
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Pagination from '../../components/Pagination/Pagination';
import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import Toast from '../../components/Toast/Toast';
import { getDotTypes } from '../../services/DotTypesService';
import DotTypeRow from './DotTypeRow';
import NewDotTypeButton from './NewDotTypeButton';
import DotTypeModal from './DotTypeModal/DotTypeModal';


function DotTypes() {

    const defaultLocation = useLocation();

    const { nome } = useParams();

    const [search, setSearch] = useState(nome || '');

    const [notification, setNotification] = useState({ type: '', text: '' });

    const history = useHistory();

    const [dottypes, setDotTypes] = useState([]);

    const [editDotType, setEditDotType] = useState({});



    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }


    const [count, setCount] = useState(0);

    const [page, setPage] = useState(parseInt(getPage()));

    function getDotTypesCall(token) {
        getDotTypes(search, page || 1, token)
            .then(result => {
                console.log(page);
                console.log(result.rows);

                setDotTypes(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }
    // paginacao
    useEffect(() => {
        const token = localStorage.getItem('token');

        getDotTypesCall(token);
    }, [page, search])

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history])

    function onSearchChange(event) {
        setSearch(event.target.value);
        console.log(event.target.value);
    }

    // tratamento do formulario

    function onNewDotTypeClick(event) {
        setEditDotType(
            {
                nome: '',
                isActive: false
            })
    }

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        console.log('OnEditClick...');
        console.log(id);
        console.log(editDotType)
        setEditDotType(dottypes.find(u => u.id == id));
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                {/* Cabeçalho da página */}
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">DotTypes...</h1>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewDotTypeButton onClick={onNewDotTypeClick} />
                        </div>
                        <div className="ms-2 ms-lg-3">
                            {/* Campo para consulta */}

                            <SearchSymbol placeholder="Projeto" onChange={onSearchChange} />
                        </div>
                    </div>
                </div>

                {/* <h1 className="h4">3 - Pontos - Montar tela..</h1> */}

                <div className="row">
                    <div className="col-12">
                        {/* Corpo da pagina */}
                        {/* INICIO DA TABELA */}
                        <div className="card card-body border-0 shadow table-wrapper table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th className="border-gray-200">View</th>
                                        <th className="border-gray-200">Código</th>
                                        <th className="border-gray-200">Nome</th>
                                        <th className="border-gray-200">Atualizado</th>
                                        <th className="border-gray-200">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dottypes.map(dottypes => (
                                            <DotTypeRow key={dottypes.id} data={dottypes} onEditClick={onEditClick} />
                                        ))
                                    }
                                </tbody>
                            </table>
                            <Pagination count={count} />
                        </div>
                        {/* FINAL DA TABELA */}


                    </div>
                </div>
                {/* Rodape da página */}
                <Footer />
            </main>
            <DotTypeModal data={editDotType} onSubmit={onModalSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    );
}

export default DotTypes;
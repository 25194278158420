import React, { useEffect, useState, useRef } from 'react';
import { getDevices, getDevice } from '../../services/DevicesService';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DeviceRow from './DeviceRow';
import NewButton from '../../components/Button/NewButton';
import Pagination from '../../components/Pagination/Pagination';
import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';

import SymbolModal from './SymbolModal';

function Beacons() {
    const { alias } = useParams();
    const [search, setSearch] = useState(alias || '');
    const [notification, setNotification] = useState({ type: '', text: '' });


    const [devices, setDevices] = useState([]);
    const [device, setDevice] = useState([]);
    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }


    const history = useHistory();

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(parseInt(getPage()));


    const [editDevice, setEditDevice] = useState([]);


    function getDevicesCall(token) {
        getDevices(search, page || 1, token)
            .then(result => {
                console.log(page);
                console.log(result.rows);

                setDevices(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }

    function getDeviceCall(id, token) {
        getDevice(id, token)
            .then(result => {

                console.log(result.rows);

                setDevice(result.rows);

            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }

    // paginacao
    useEffect(() => {
        const token = localStorage.getItem('token');

        getDevicesCall(token);
    }, [page, search])

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history])

    function onSearchChange(event) {
        setSearch(event.target.value);
        console.log(event.target.value);
    }
    function onViewClick(event) {
        const token = localStorage.getItem('token');
        const id = parseInt(event.target.id.replace("view", ""));
        console.log("On view click");
        console.log(id);
        // setNotification({ type: 'info', text: id });
        getDeviceCall(id, token);
        // setViewOrder(orders.find(o => o.id === id));
    }

    function onDeleteClick(event) {
        const id = parseInt(event.target.id.replace("view", ""));
        console.log("On Delete click");
        console.log(id);

        const device = devices.find(m => m.id === id);

        setEditDevice({ editDevice });
    }


    function onMonitorSubmit(order) {
        history.go(0);
    }


    const [viewDevice, setviewDevice] = useState({
        id: '',
        alias: '',
        ativo: '',
        bateria: ''

    });


    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Beacons</h1>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        <div className="d-inline-flex align-items-center">

                        </div>
                        <div className="d-inline-flex align-items-center">
                            <NewButton title="Novo Dispositivo" />
                        </div>

                        <div className="btn-group ms-2 ms-lg-3">
                            <SearchSymbol placeholder="Beacon Nome" onChange={onSearchChange} />
                        </div>

                    </div>
                </div>

                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">View</th>
                                <th className="border-gray-200">Código</th>
                                <th className="border-gray-200">Alias</th>
                                <th className="border-gray-200">Nome</th>
                                <th className="border-gray-200">Atualizado</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Bateria</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                devices.map(devices => (
                                    <DeviceRow key={devices.id} data={devices} onClick={onViewClick} onDeleteClick={onDeleteClick} />
                                ))
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>


                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
            <SymbolModal data={device} />
        </React.Fragment>
    );
}

export default Beacons;
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';

function Dashboard() {

    const history = useHistory();


    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Dashboard</h1>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        <div className="d-inline-flex align-items-center">

                        </div>
                        <div className="ms-2 ms-lg-3">

                        </div>
                    </div>
                </div>
                <div>

                    <h1>ola</h1>

                    {/* <div style={{ height: 10 }}>
                        Hello World!
                    </div> */}

                    {/* // Style resultante: '10%' */}
                    {/* <div style={{ height: '10%' }}>
                        Hello World!
                    </div> */}
                    {/* Tela dividida em 2 colunas */}
                    <div className="row">

                        <div className="col-12 mb-4">
                            <div className="card border-0 shadow">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h2 className="fs-5 fw-bold mb-0">Page visits</h2>
                                        </div>
                                        <div className="col text-end">
                                            <a href="#" className="btn btn-sm btn-primary">See all</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="border-bottom" scope="col">Page name</th>
                                                <th className="border-bottom" scope="col">Page Views</th>
                                                <th className="border-bottom" scope="col">Page Value</th>
                                                <th className="border-bottom" scope="col">Bounce rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className="text-gray-900" scope="row">
                                                    /demo/admin/index.html
                                                </th>
                                                <td className="fw-bolder text-gray-500">
                                                    3,225
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    $20
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    <div className="d-flex">
                                                        <svg className="icon icon-xs text-danger me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                        42,55%
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-gray-900" scope="row">
                                                    /demo/admin/forms.html
                                                </th>
                                                <td className="fw-bolder text-gray-500">
                                                    2,987
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    0
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    <div className="d-flex">
                                                        <svg className="icon icon-xs text-success me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                                        43,24%
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-gray-900" scope="row">
                                                    /demo/admin/util.html
                                                </th>
                                                <td className="fw-bolder text-gray-500">
                                                    2,844
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    294
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    <div className="d-flex">
                                                        <svg className="icon icon-xs text-success me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                                        32,35%
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-gray-900" scope="row">
                                                    /demo/admin/validation.html
                                                </th>
                                                <td className="fw-bolder text-gray-500">
                                                    2,050
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    $147
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    <div className="d-flex">
                                                        <svg className="icon icon-xs text-danger me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                        50,87%
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="text-gray-900" scope="row">
                                                    /demo/admin/modals.html
                                                </th>
                                                <td className="fw-bolder text-gray-500">
                                                    1,483
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    $19
                                                </td>
                                                <td className="fw-bolder text-gray-500">
                                                    <div className="d-flex">
                                                        <svg className="icon icon-xs text-success me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                                        26,12%
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-xxl-6 mb-4">
                            <div className="card border-0 shadow">
                                <div className="card-header border-bottom d-flex align-items-center justify-content-between">
                                    <h2 className="fs-5 fw-bold mb-0">Team members</h2>
                                    <a href="#" className="btn btn-sm btn-primary">See all</a>
                                </div>
                                <div className="card-body">
                                    <ul className="list-group list-group-flush list my--3">
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    {/* <!-- Avatar --> */}
                                                    <a href="#" className="avatar">
                                                        <img className="rounded" alt="Image placeholder" src="../../assets/img/team/profile-picture-1.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col-auto ms--2">
                                                    <h4 className="h6 mb-0">
                                                        <a href="#">Chris Wood</a>
                                                    </h4>
                                                    <div className="d-flex align-items-center">
                                                        <div className="bg-success dot rounded-circle me-1"></div>
                                                        <small>Online</small>
                                                    </div>
                                                </div>
                                                <div className="col text-end">
                                                    <a href="#" className="btn btn-sm btn-secondary d-inline-flex align-items-center">
                                                        <svg className="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                                                        Invite
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    {/* <!-- Avatar --> */}
                                                    <a href="#" className="avatar">
                                                        <img className="rounded" alt="Image placeholder" src="../../assets/img/team/profile-picture-2.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col-auto ms--2">
                                                    <h4 className="h6 mb-0">
                                                        <a href="#">Jose Leos</a>
                                                    </h4>
                                                    <div className="d-flex align-items-center">
                                                        <div className="bg-warning dot rounded-circle me-1"></div>
                                                        <small>In a meeting</small>
                                                    </div>
                                                </div>
                                                <div className="col text-end">
                                                    <a href="#" className="btn btn-sm btn-secondary d-inline-flex align-items-center">
                                                        <svg className="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path></svg>
                                                        Message
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    {/* <!-- Avatar --> */}
                                                    <a href="#" className="avatar">
                                                        <img className="rounded" alt="Image placeholder" src="../../assets/img/team/profile-picture-3.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col-auto ms--2">
                                                    <h4 className="h6 mb-0">
                                                        <a href="#">Bonnie Green</a>
                                                    </h4>
                                                    <div className="d-flex align-items-center">
                                                        <div className="bg-danger dot rounded-circle me-1"></div>
                                                        <small>Offline</small>
                                                    </div>
                                                </div>
                                                <div className="col text-end">
                                                    <a href="#" className="btn btn-sm btn-secondary d-inline-flex align-items-center">
                                                        <svg className="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path></svg>
                                                        Message
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    {/* <!-- Avatar --> */}
                                                    <a href="#" className="avatar">
                                                        <img className="rounded" alt="Image placeholder" src="../../assets/img/team/profile-picture-4.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col-auto ms--2">
                                                    <h4 className="h6 mb-0">
                                                        <a href="#">Neil Sims</a>
                                                    </h4>
                                                    <div className="d-flex align-items-center">
                                                        <div className="bg-danger dot rounded-circle me-1"></div>
                                                        <small>Offline</small>
                                                    </div>
                                                </div>
                                                <div className="col text-end">
                                                    <a href="#" className="btn btn-sm btn-secondary d-inline-flex align-items-center">
                                                        <svg className="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path></svg>
                                                        Message
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* progress */}
                        <div className="col-12 col-xxl-6 mb-4">
                            <div className="card border-0 shadow">
                                <div className="card-header border-bottom d-flex align-items-center justify-content-between">
                                    <h2 className="fs-5 fw-bold mb-0">Progress track</h2>
                                    <a href="#" className="btn btn-sm btn-primary">See tasks</a>
                                </div>
                                <div className="card-body">
                                    {/* <!-- Project 1 --> */}
                                    <div className="row mb-4">
                                        <div className="col-auto">
                                            <svg className="icon icon-sm text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <div className="col">
                                            <div className="progress-wrapper">
                                                <div className="progress-info">
                                                    <div className="h6 mb-0">Rocket - SaaS Template</div>
                                                    <div className="small fw-bold text-gray-500"><span>75 %</span></div>
                                                </div>
                                                <div className="progress mb-0">
                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Project 2 --> */}
                                    <div className="row align-items-center mb-4">
                                        <div className="col-auto">
                                            <svg className="icon icon-sm text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <div className="col">
                                            <div className="progress-wrapper">
                                                <div className="progress-info">
                                                    <div className="h6 mb-0">Themesberg - Design System</div>
                                                    <div className="small fw-bold text-gray-500"><span>60 %</span></div>
                                                </div>
                                                <div className="progress mb-0">
                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Project 3 --> */}
                                    <div className="row align-items-center mb-4">
                                        <div className="col-auto">
                                            <svg className="icon icon-sm text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <div className="col">
                                            <div className="progress-wrapper">
                                                <div className="progress-info">
                                                    <div className="h6 mb-0">Homepage Design in Figma</div>
                                                    <div className="small fw-bold text-gray-500"><span>45 %</span></div>
                                                </div>
                                                <div className="progress mb-0">
                                                    <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style={{ width: '45%' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Project 4 --> */}
                                    <div className="row align-items-center mb-3">
                                        <div className="col-auto">
                                            <svg className="icon icon-sm text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <div className="col">
                                            <div className="progress-wrapper">
                                                <div className="progress-info">
                                                    <div className="h6 mb-0">Backend for Themesberg v2</div>
                                                    <div className="small fw-bold text-gray-500"><span>34 %</span></div>
                                                </div>
                                                <div className="progress mb-0">
                                                    <div className="progress-bar bg-danger" role="progressbar" aria-valuenow={'34'} aria-valuemin="0" aria-valuemax="100" style={{ width: '34%' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </main>

        </React.Fragment>
    );
}

export default Dashboard;
import React, { useRef, useState, useEffect } from 'react';
import { saveUser } from '../../../services/UsersService';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
// import ProgressBar from 'react-bootstrap/ProgressBar';



/**
 * props:
 * - data
 * - onSubmit
 */
function UserModal(props) {

    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [notification, setNotification] = useState({});
    const [error, setError] = useState('');

    const [user, setUser] = useState({});
    const [dtfinal, setDtFinal] = useState('');


    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        const token = localStorage.getItem('token');
        saveUser(user.id, user, token)
            .then(result => {
                //  btnClose.current.click();
                if (result)
                    setNotification({ type: 'success', text: `Settings updated successfully!` });
                else
                    setNotification({ type: 'error', text: `Can't update the settings.` });
                btnClose.current.click(); 
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }

    function onInputChange(event) {
        console.log('On imput change');
        setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        console.log('use Effect');
        console.log(props.data);


        setUser(props.data);
    }, [props.data.id])

    // style={width:25%} 
    // const styles = StyleSheet.create({
    //     barra: {
    //         width: 25
    //     },
    // });

    function getDate(timestamp) {

        const date = new Date(timestamp);

        try {
            return Intl.DateTimeFormat('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short'
            })
                .format(date);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {

        const dataFinal = getDate(user.updatedAt);
        setDtFinal(dataFinal)

    })
    const styles = { width: "100%" };
    const data = dtfinal;

    return (
        <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}User</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>

                                {/* <div className="progress">

                                    <div className="progress-bar" role="progressbar" htmlStyle={{ styles }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">25%</div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="name">Nome:</label>
                                        <input type="text" id="nome" className="form-control" onChange={onInputChange} defaultValue={user.nome} placeholder="Nome do user" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input type="text" id="email" className="form-control" onChange={onInputChange} defaultValue={user.email} placeholder="Email" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="password">Password:</label>
                                        <input type="text" id="password" className="form-control" onChange={onInputChange} placeholder="Password" />
                                    </div>
                                </div>
                                {/* </div>

                            <div className="row"> */}
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone:</label>
                                        <input type="text" id="phone" className="form-control" onChange={onInputChange} defaultValue={user.phone} placeholder="Telefone" />
                                    </div>
                                </div>

                                {/* Atualizado em  */}
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="phone">Atualizado:</label>
                                        <input type="text" id="updatedAt" className="form-control" defaultValue={data} placeholder="Telefone" disabled />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <SwitchInput id="isActive" text="Is Active?" onChange={onInputChange} isChecked={(user.isActive)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                            error
                                ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserModal;
import React from 'react';

/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 * - onDeleteClick
 */
function ProjetoRow(props) {


    function getDate(timestamp) {
        const date = new Date(timestamp);
        return Intl.DateTimeFormat('pt-BR', {
            dateStyle: 'short',
            timeStyle: 'short'
        })
            .format(date);
    }

    // function getStatus(status) {
    //     let className;
    //     switch (status) {
    //         case 1: className = 'badge bg-success py-1'; break;
    //         case 0: className = 'badge bg-danger py-1'; break;
    //         default: className = 'badge bg-warning py-1';
    //     }
    //     //     return (<span className={className}>{status.split('_')[0]}</span>)
    //     return (<span className={className}> {status}</span>)
    // }
    // function getStatus(isActive) {
    //     return isActive ? "badge bg-success py-1" : "text-danger";
    // }

    // function getActiveText(isActive) {
    //     return isActive ? "RUNNING" : "STOPPED";
    // }

    return (
        <tr>
            <td>
                <button id={"view" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalViewOrder">
                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                    </svg>
                </button>

                <button id={"edit" + props.data.id} type="button" className="btn btn-secondary btn-xs ms-2" title="Edit this User" data-bs-toggle="modal" data-bs-target="#modalDotType" onClick={props.onEditClick}>
                    <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                </button>
            </td>
            <td>
                {props.data.id}
            </td>
            <td>
                {props.data.nome}
            </td>
            <td>

                <span className="fw-normal">{getDate(props.data.updatedAt)}</span>
            </td>
            <td>
                {
                    props.data.isActive
                        ? <svg className="icon icon-xs me-2" fill="green" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                        : <svg className="icon icon-xs me-2" fill="red" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg>
                }
            </td>




        </tr>
    )
}

export default ProjetoRow;
import React from 'react';

/**
 * props:
 * - name
 * - Titulo
 * - Campo
 * - placeholder
 * - rows
 * - cols
 * - onChange
 * - size
 */
function TextAreaEdit(props) {
    return (

        // <div className="col-sm-3 mb-3">
        <div className={"col-sm-" + props.size + " mb-3"}>
            <div className="form-group">
                <label htmlFor={props.name}>{props.titulo}</label>
                {/* <textarea className="form-control" placeholder="Enter your message..." id="textarea" rows="6" cols="12" defaultValue={editInformacao.resumo}></textarea> */}
                <textarea className="form-control" id={props.name} name={props.name}  rows={props.rows} cols={props.cols} placeholder={props.placeholder} defaultValue={props.field}  onChange={props.onChange} />
            </div>
        </div>

    )
}

export default TextAreaEdit;
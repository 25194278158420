import React, { useRef, useState, useEffect } from 'react';
import { savePonto } from '../../../services/PontosService';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import SelectProjeto from '../PontoModal/SelectProjeto';
import SelectDotType from '../PontoModal/SelectDotType';

/**
 * props:
 * - data
 * - onSubmit
 */
function PontoModal(props) {

    const [error, setError] = useState('');
    const [ponto, setPonto] = useState({});
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        const token = localStorage.getItem('token');
        const formData = new FormData();

        for (const key in ponto) {
            formData.append(key, ponto[key]);
        }
        if (image) {
            formData.append('imagen', image);
        }

        savePonto(ponto.id, formData, token)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            });
    }

    function onInputChange(event) {
        const { id, value } = event.target;
        setPonto(prevState => ({ ...prevState, [id]: value }));
    }

    function onImageChange(event) {
        const file = event.target.files[0];
        setImage(file);
        setImagePreviewUrl(URL.createObjectURL(file));
    }

    useEffect(() => {
        setPonto(props.data);
    }, [props.data.id]);

    return (
        <div className="modal fade" id="modalPonto" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Tipo ponto</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="sendGridKey">Projeto</label>
                                        <SelectProjeto id={ponto.projetoId} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="sendGridKey">Tipo do ponto</label>
                                        <SelectDotType id={ponto.dotTypesId} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="name">Nome:</label>
                                        <input type="text" id="nome" className="form-control" onChange={onInputChange} defaultValue={ponto.nome} placeholder="Nome do ponto" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="formFile" className="form-label">Imagem do ponto</label>
                                    <input className="form-control" type="file" id="imagen" onChange={onImageChange} />
                                    {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" className="img-thumbnail mt-2" />}
                                    {ponto.imagen && <img src={'https://api.t2i-service.com.br/api/images/' + ponto.imagen} alt="Preview" className="img-thumbnail mt-2" />}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <SwitchInput id="isActive" text="Is Active?" onChange={onInputChange} isChecked={ponto.isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {error && <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>}
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PontoModal;

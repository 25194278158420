import React, { useState, useEffect } from 'react';

import { getAllDotTypes, getActiveDotTypes } from '../../../services/DotTypesService';

/**
 * props:
 * - id
 * - onChange
 */
function SelectDotType(props) {

    const [dottypes, setDotTypes] = useState(["LOADING"]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        let promise;
        if (props.id > 0) {
            promise = getAllDotTypes(token);
        } else {

            promise = getActiveDotTypes(token);
        }

        promise.then(dottypeObjects => {
            setDotTypes(dottypeObjects);
        })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setDotTypes(['ERROR']);
            })
    }, [props.id])

    return (
        <select id="dotTypesId" className="form-select" value={props.id} onChange={props.onChange}>
            <option value="">Select...</option>
            {
                dottypes && dottypes.length > 0
                    ? dottypes.map(dottype => (<option key={"dottype" + dottype.id} value={dottype.id}>{dottype.nome}</option>))
                    : <React.Fragment></React.Fragment>
            }
        </select>
    )
}

export default SelectDotType;

import React from 'react';

/**
 * props:
 * - tipo
 * - bateria
 */
function ProgressBattery(props) {
    return (

        <div className="col">
            <div className="progress-wrapper">
                <div className="progress-info">
                    <div className="h6 mb-0">Bateria</div>
                    <div className="small fw-bold text-gray-500"><span>{props.bateria} %</span></div>
                </div>
                <div className="progress mb-0 progress-xl">
                    <div className={"progress-bar " + props.tipo} role="progressbar" aria-valuenow={props.bateria} aria-valuemin="0" aria-valuemax="100" style={{ width: props.bateria + '%' }}></div>
                </div>
            </div>
        </div>

    )
}

export default ProgressBattery;
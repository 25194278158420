import React from 'react';

function Footer() {
    return (
        <footer className="bg-white rounded shadow p-5 mb-4 mt-4">
            <div className="row">

                <div className="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
                    <p className="mb-0 text-center text-lg-start">© 2010-<span className="current-year">2021</span> <a
                        className="text-primary fw-normal" href="https://www.t2igroup.com/">T2I-Group</a></p>
                </div>

                <div className="col-12 col-md-8 col-xl-6 text-center text-lg-start">

                    <ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
                        <li className="list-inline-item px-0 px-sm-2">
                            <a href="https://www.t2igroup.com/#:~:text=Quem%20somos,-Atua%C3%A7%C3%A3o">About</a>
                        </li>

                        <li className="list-inline-item px-0 px-sm-2">
                            <a href="https://www.t2igroup.com/">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;

const DEVICES_URL = `${API_URL}/devices/`;

export async function getDevices(alias, page, token) {
    const settingsUrl = `${DEVICES_URL}${alias}?page=${page}`;
    const headers = { 'authorization': token }

    console.log(settingsUrl);
    const response = await axios.get(settingsUrl, { headers });
    return response.data;
}

export async function getDevice(id, token) {
    const settingsUrl = `${DEVICES_URL}id/${id}`;
    const headers = { 'authorization': token }

    console.log(settingsUrl);
    const response = await axios.get(settingsUrl, { headers });
    return response.data;
}


export async function saveDevice(id, newDevice, token) {
    const headers = { 'authorization': token };
    let response;
    if (id)
        response = await axios.patch(`${DEVICES_URL}${id}`, newDevice, { headers });
    else
        response = await axios.post(DEVICES_URL, newDevice, { headers });
    return response.data;
}

import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;

const NEELOS_URL = `${API_URL}/neelo/`;

export async function getNeelos(titulo, page, token) {
    const settingsUrl = `${NEELOS_URL}${titulo}?page=${page}`;
    const headers = { 'authorization': token }

    console.log(settingsUrl);
    const response = await axios.get(settingsUrl, { headers });
    return response.data;
}

export async function getInformacao(id, token) {
    const settingsUrl = `${NEELOS_URL}id/${id}`;
    const headers = { 'authorization': token }
   // console.log(" executando getInformacao.....");
   // console.log(settingsUrl);
    const response = await axios.get(settingsUrl, { headers });
   // console.log(" Retormno de getInformacao.....");
   // console.log(response.data.neelo);
    return response.data.neelo;
}


// export async function getAllNeelos(token) {
//     const headers = { 'authorization': token }
//     const response = await axios.get(`${NEELOS_URL}all`, { headers });
//     return response.data;
// }

// export async function getActiveNeelos(token) {
//     const headers = { 'authorization': token }
//     const response = await axios.get(`${NEELOS_URL}active`, { headers });
//     return response.data;
// }


export async function saveNeelo(id, newNeelo, token) {
    const headers = { 'authorization': token };
    let response;
    if (id)
        response = await axios.patch(`${NEELOS_URL}${id}`, newNeelo, { headers });
    else
        response = await axios.post(NEELOS_URL, newNeelo, { headers });
    return response.data;
}


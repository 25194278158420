import React from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import Login from './public/Login/Login';
import Settings from './private/Settings/Settings';
import Dashboard from './private/Dashboard/Dashboard';
import Beacons from './private/Beacons/Beacons';
import Pontos from './private/Pontos/Pontos';
import Projetos from './private/Projetos/Projetos';
import Informacoes from './private/Informacoes/Informacoes';
import EditInformacao from './private/Informacoes/edit_informacao';
import Users from './private/Users/Users';
// import CadInformacao from './private/Informacoes/cad_informacao';
import EditBeacon from './private/Beacons/EditBeacon';
import DotTypes from './private/DotTypes/DotTypes';


function Routes() {

    function PrivateRoute({ children, ...rest }) {
        return (
            <Route {...rest} render={() => {
                return localStorage.getItem('token')
                    ? children
                    : <Redirect to="/" />
            }} />
        )
    }

    return (
        <BrowserRouter>
            <Route path="/" exact>
                <Login />
            </Route>

            <PrivateRoute path="/settings">
                <Settings />
            </PrivateRoute>

            <PrivateRoute path="/dashboard">
                <Dashboard />
            </PrivateRoute>

            <PrivateRoute path="/beacons">
                <Beacons />
            </PrivateRoute>

            <PrivateRoute path="/editbeacon/:id?">
                <EditBeacon />
            </PrivateRoute>

            <PrivateRoute path="/projetos/:id?">
                <Projetos />
            </PrivateRoute>

            <PrivateRoute path="/dottypes/:id?">
                <DotTypes />
            </PrivateRoute>

            <PrivateRoute path="/pontos/:id?">
                <Pontos />
            </PrivateRoute>

            <PrivateRoute path="/informacoes">
                <Informacoes />
            </PrivateRoute>

            <PrivateRoute path="/editinformacao/:id?">
                <EditInformacao />
            </PrivateRoute>

            <PrivateRoute path="/users">
                <Users />
            </PrivateRoute>

        </BrowserRouter>
    )
}

export default Routes;
import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;

const DOTTYPES_URL = `${API_URL}/dottypes/`;

export async function getDotTypes(alias, page, token) {
    const dottypesUrl = `${DOTTYPES_URL}${alias}?page=${page}`;
    const headers = { 'authorization': token }

    console.log(dottypesUrl);
    const response = await axios.get(dottypesUrl, { headers });
    return response.data;
}

export async function getDotType(id, token) {
    const dottypesUrl = `${DOTTYPES_URL}id/${id}`;
    const headers = { 'authorization': token }

    console.log(dottypesUrl);
    const response = await axios.get(dottypesUrl, { headers });
    return response.data;
}


export async function getAllDotTypes(token) {
    const headers = { 'authorization': token }
    const response = await axios.get(`${DOTTYPES_URL}all`, { headers });
    return response.data;
}

export async function getActiveDotTypes(token) {
    const headers = { 'authorization': token }
    const response = await axios.get(`${DOTTYPES_URL}active`, { headers });
    return response.data;
}


export async function saveDotType(id, newDotType, token) {
    const headers = { 'authorization': token };
    let response;
    if (id)
        response = await axios.patch(`${DOTTYPES_URL}${id}`, newDotType, { headers });
    else
        response = await axios.post(DOTTYPES_URL, newDotType, { headers });
    return response.data;
}

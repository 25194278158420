import axios from './BaseService';

const USERS_URL = `${process.env.REACT_APP_API_URL}/users/`;

export async function getActiveUsers(token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${USERS_URL}active`, { headers });
    return response.data;
}

export async function getUsers(search, page, token) {
    const usersUrl = `${USERS_URL}${search ? search : ''}?page=${page}`;

    const headers = { 'authorization': token };
    const response = await axios.get(usersUrl, { headers });
    return response.data;//{count, rows}
}

export async function saveUser(id, newUser, token) {
    const headers = { 'authorization': token };
    let response;
    if (id)
        response = await axios.patch(`${USERS_URL}${id}`, newUser, { headers });
    else
        response = await axios.post(USERS_URL, newUser, { headers });
    return response.data;
}

export async function deleteUser(id, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${USERS_URL}${id}`, { headers });
    return response.data;
}

// export async function startUser(id, token) {
//     const headers = { 'authorization': token };
//     const response = await axios.post(`${USERS_URL}${id}/start`, {}, { headers });
//     return response.data;
// }

// export async function stopUser(id, token) {
//     const headers = { 'authorization': token };
//     const response = await axios.post(`${USERS_URL}${id}/stop`, {}, { headers });
//     return response.data;
// }

// export async function resetUserPassword(id, token) {
//     const headers = { 'authorization': token };
//     const response = await axios.post(`${USERS_URL}${id}/reset`, {}, { headers });
//     return response.data;
// }
import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import TextEdit from '../../components/TextEdit/TextEdit';
import TextAreaEdit from '../../components/TextAreaEdit/TextAreaEdit';
import SwitchInput from '../../components/SwitchInput/SwitchInput';
import Toast from '../../components/Toast/Toast';
import Footer from '../../components/Footer/Footer';
import { getInformacao, saveNeelo } from '../../services/NeelosService';
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
function EditInformacao(props) {
    // inicio da classe de edição
    const defaultLocation = useLocation();

    const [editInformacao, setEditInformacao] = useState({});
    const [notification, setNotification] = useState({});
    // const [images, setImages] = useState([{}]); // funciona
    let images = [];
    const [error, setError] = useState('');
    const [dtfinal, setDtFinal] = useState('');
    const btnSave = useRef('');
    const history = useHistory();

    const id = getId();

    useEffect(() => {
        const token = localStorage.getItem("token");


        getInformacao(id, token)
            .then(result => setEditInformacao(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });


    }, []);


    function getId(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('id');
    }



    function onInputChange(event) {

        setEditInformacao(prevState => ({ ...prevState, [event.target.id]: event.target.value }));

    }


    function onSubmit(event) {
        const token = localStorage.getItem('token');

        saveNeelo(editInformacao.id, editInformacao, token)
            .then(result => {
                //  btnClose.current.click();
                if (result)
                    setNotification({ type: 'success', text: `Settings updated successfully!` });
                else
                    setNotification({ type: 'error', text: `Can't update the settings.` });

                if (props.onSubmit) props.onSubmit(result);
                history.go(-1)
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }

    function getDate(timestamp) {

        const date = new Date(timestamp);

        try {
            return Intl.DateTimeFormat('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short'
            })
                .format(date);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {

        const dataFinal = getDate(editInformacao.updatedAt);
        setDtFinal(dataFinal);

    });

    function setaImagens() {

        images.push(
            {
                original: editInformacao.foto_1,
                thumbnail: editInformacao.foto_1,
            },
            {
                original: editInformacao.foto_2,
                thumbnail: editInformacao.foto_2,
            },
            {
                original: editInformacao.foto_3,
                thumbnail: editInformacao.foto_3,
            },
            {
                original: editInformacao.foto_4,
                thumbnail: editInformacao.foto_4,
            },

        )
    }

    useEffect(() => {
        setaImagens();
    });
    const data = dtfinal;
    const figura = images;


    return (

        <React.Fragment>
            <Menu />
            <main className="content">
                {/* Cabeçalho da página */}
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        {/* <h1 className="h4">Users...</h1> */}
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent mb-0">
                                <li className="breadcrumb-item">
                                    <a href="#">
                                        <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                    </a>
                                </li>
                                {/* <li className="breadcrumb-item"><a href="#">Category</a></li> */}
                                <li className="breadcrumb-item active" aria-current="page">Informação</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {/* corpo da página */}

                <div className="row">
                    <div className="col-12">
                        {/* Corpo da pagina */}
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 my-4">[Manutenção da informação.]</h2>
                            {/* campos da tela */}
                            <div className="row">

                                <TextEdit titulo="Titulo:" name="titulo" field={editInformacao.titulo} placeholder="Titulo do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextAreaEdit titulo="Resumo:" name="resumo" field={editInformacao.resumo} placeholder="Digite o resumo" onChange={onInputChange} size="12" rows="6" cols="12" ></TextAreaEdit>
                                <TextAreaEdit titulo="Descrição:" name="descricao" field={editInformacao.descricao} placeholder="Digite a descrição" onChange={onInputChange} size="12" rows="8" cols="12" ></TextAreaEdit>
                                <TextEdit titulo="Site:" name="site" field={editInformacao.site} placeholder="site do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextEdit titulo="1 - Foto:" name="foto_1" field={editInformacao.foto_1} placeholder="foto do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextEdit titulo="2 - Foto:" name="foto_2" field={editInformacao.foto_2} placeholder="foto do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextEdit titulo="3 - Foto:" name="foto_3" field={editInformacao.foto_3} placeholder="foto do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextEdit titulo="4 - Foto:" name="foto_4" field={editInformacao.foto_4} placeholder="foto do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextEdit titulo="Filme:" name="filme" field={editInformacao.filme} placeholder="foto do local" onChange={onInputChange} size="12" ></TextEdit>
                                <TextEdit titulo="Latitude:" name="latitude" field={editInformacao.latitude} placeholder="foto do local" onChange={onInputChange} size="3" ></TextEdit>
                                <TextEdit titulo="Longitude:" name="longitude" field={editInformacao.longitude} placeholder="foto do local" onChange={onInputChange} size="3" ></TextEdit>
                                <TextEdit titulo="Atualizado em:" name="updatedAt" field={data} placeholder="Atualizado" size="3" />


                                <div className="col-md-6 mb-3">
                                    <ImageGallery items={figura} thumbnailPosition="left" />
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-group">

                                            <SwitchInput id="isActive" text="Is Active?" onChange={onInputChange} isChecked={(editInformacao.isActive)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {
                                        error
                                            ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                                </div>
                            </div>
                            {/* final dos campos da tela */}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment >
    )
}

export default EditInformacao;
import React, { useRef, useState, useEffect } from 'react';
import { saveProjeto } from '../../../services/ProjetosService';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';



/**
 * props:
 * - data
 * - onSubmit
 */
function ProjetoModal(props) {

    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [error, setError] = useState('');

    const [projeto, setProjeto] = useState({});

    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        const token = localStorage.getItem('token');
        saveProjeto(projeto.id, projeto, token)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }

    function onInputChange(event) {
        console.log('On imput change');
        setProjeto(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        console.log('use Effect');
        console.log(props.data);

        setProjeto(props.data);
    }, [props.data.id])

    return (
        <div className="modal fade" id="modalProjeto" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Projeto</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="name">Nome:</label>
                                        <input type="text" id="nome" className="form-control" onChange={onInputChange} defaultValue={projeto.nome} placeholder="Nome do projeto" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Descrição:</label>
                                        <input type="text" id="descricao" className="form-control" onChange={onInputChange} defaultValue={projeto.descricao} placeholder="Descrição do projeto" />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <SwitchInput id="ativo" text="Is Active?" onChange={onInputChange} isChecked={(projeto.ativo)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                            error
                                ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjetoModal;
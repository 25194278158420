import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;

const PONTOS_URL = `${API_URL}/pontos/`;

export async function getPontos(alias, page, token) {
    const pontosUrl = `${PONTOS_URL}${alias}?page=${page}`;
    const headers = { 'authorization': token }

    console.log(pontosUrl);
    const response = await axios.get(pontosUrl, { headers });
    return response.data;
}

export async function getPonto(id, token) {
    const pontosUrl = `${PONTOS_URL}id/${id}`;
    const headers = { 'authorization': token }

    console.log(pontosUrl);
    const response = await axios.get(pontosUrl, { headers });
    return response.data;
}


export async function savePonto(id, newPonto, token) {
    const headers = { 'authorization': token };
    let response;
    // console.log('Url....');
    //  console.log(PONTOS_URL);
    if (id)
        response = await axios.patch(`${PONTOS_URL}${id}`, newPonto, { headers });
    else
        response = await axios.post(PONTOS_URL, newPonto, { headers });
    return response.data;
}

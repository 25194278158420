import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;

const PROJETOS_URL = `${API_URL}/projetos/`;

export async function getProjetos(nome, page, token) {
    const settingsUrl = `${PROJETOS_URL}${nome}?page=${page}`;
    const headers = { 'authorization': token }

    console.log(settingsUrl);
    const response = await axios.get(settingsUrl, { headers });
    return response.data;
}


export async function getAllProjetos(token) {
    const headers = { 'authorization': token }
    const response = await axios.get(`${PROJETOS_URL}all`, { headers });
    return response.data;
}

export async function getActiveProjetos(token) {
    const headers = { 'authorization': token }
    const response = await axios.get(`${PROJETOS_URL}active`, { headers });
    return response.data;
}


export async function saveProjeto(id, newProjeto, token) {
    const headers = { 'authorization': token };
    let response;
    if (id)
        response = await axios.patch(`${PROJETOS_URL}${id}`, newProjeto, { headers });
    else
        response = await axios.post(PROJETOS_URL, newProjeto, { headers });
    return response.data;
}


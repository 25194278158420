import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import Menu from '../../components/Menu/Menu';
import ProgressBattery from '../../components/ProgressBattery/ProgressBattery';
import SwitchInput from '../../components/SwitchInput/SwitchInput';
import TextEdit from '../../components/TextEdit/TextEdit';
import { getDevice, saveDevice } from '../../services/DevicesService';
import Toast from '../../components/Toast/Toast';


function EditBeacon(props) {
    const defaultLocation = useLocation();

    const [editBeacon, setEditBeacon] = useState({});
    const [notification, setNotification] = useState({});
    const [error, setError] = useState('');
    const [dtfinal, setDtFinal] = useState('');
    const btnSave = useRef('');

    const history = useHistory();




    const id = getId();

    useEffect(() => {
        const token = localStorage.getItem("token");


        getDevice(id, token)
            .then(result => setEditBeacon(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }, []);


    function getId(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('id');
    }

    function getBattery(bateria) {
        let tipo;

        if (bateria < 69) {
            tipo = "bg-warning";

        }
        if (bateria <= 25) {
            tipo = "bg-danger";

        }
        if (bateria > 40) {
            tipo = "bg-info";

        }
        if (bateria > 69) {
            tipo = "bg-success";

        }

        return (<ProgressBattery tipo={tipo} bateria={bateria}></ProgressBattery>)
    }


    function onInputChange(event) {

        setEditBeacon(prevState => ({ ...prevState, [event.target.id]: event.target.value }));

    }


    function onSubmit(event) {
        const token = localStorage.getItem('token');

        saveDevice(editBeacon.id, editBeacon, token)
            .then(result => {
                //  btnClose.current.click();
                if (result)
                    setNotification({ type: 'success', text: `Settings updated successfully!` });
                else
                    setNotification({ type: 'error', text: `Can't update the settings.` });

                if (props.onSubmit) props.onSubmit(result);
                history.go(-1)
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }

    function getDate(timestamp) {

        const date = new Date(timestamp);

        try {
            return Intl.DateTimeFormat('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short'
            })
                .format(date);
        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {

        const dataFinal = getDate(editBeacon.updatedAt);
        setDtFinal(dataFinal)

    })

    const data = dtfinal;

    return <React.Fragment>
        <Menu />
        <main className="content">
            {/* Cabeçalho da página */}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    {/* <h1 className="h4">Users...</h1> */}
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent mb-0">
                            <li className="breadcrumb-item">
                                <a href="#">
                                    <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            {/* <li className="breadcrumb-item"><a href="#">Category</a></li> */}
                            <li className="breadcrumb-item active" aria-current="page">Beacons</li>
                        </ol>
                    </nav>
                </div>
                {/* <div className="btn-toolbar mb-md-0">
                    <div className="d-inline-flex align-items-center">
                        <NewUserButton />

                    </div>
                    <div className="ms-2 ms-lg-3">
                       
                    </div>

                </div> */}
            </div>
            <div className="row">
                <div className="col-12">
                    {/* Corpo da pagina */}
                    <div className="card card-body border-0 shadow mb-4">
                        <h2 className="h5 my-4">Manutenção de beacon</h2>
                        {/* campos da tela */}
                        <div className="row">


                            <TextEdit titulo="Nome:" name="nome" field={editBeacon.nome} placeholder="Nome dispositivo" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Alias:" name="alias" field={editBeacon.alias} placeholder="Alias dispositivo" onChange={onInputChange} size="3"  ></TextEdit>
                            <TextEdit titulo="Password:" name="pwd" field={editBeacon.pwd} placeholder="Password dispositivo" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Uuid:" name="uuid" field={editBeacon.uuid} placeholder="UUID dispositivo" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Maior:" name="maior" field={editBeacon.maior} placeholder="Maior" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Menor:" name="menor" field={editBeacon.menor} placeholder="Menor" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Intervalo:" name="intervalo" field={editBeacon.intervalo} placeholder="Intervalo" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Tx Power:" name="txpower" field={editBeacon.txpower} placeholder="Tx Power" onChange={onInputChange} size="3" ></TextEdit>
                            <TextEdit titulo="Bateria:" name="bateria" field={editBeacon.bateria} placeholder="Bateria" onChange={onInputChange} size="3" ></TextEdit>
                            <div className="col-sm-3 mb-3">
                                <label for="bateria" className="form-label">Example range</label>
                                <input type="range" className="form-range" min="0" max="100" step="5" id="bateria" defaultValue={editBeacon.bateria} onChange={onInputChange} />
                            </div>
                            <div className="col-sm-3 mb-3">
                                <div className="form-group">
                                    {getBattery(editBeacon.bateria)}
                                </div>
                            </div>
                            <TextEdit titulo="Mac Address:" name="address" field={editBeacon.address} placeholder="Mac Address" onChange={onInputChange} size="3"  ></TextEdit>
                            <TextEdit titulo="Atualizado em:" name="updatedAt" field={data} placeholder="Atualizado" size="3" />


                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">

                                        <SwitchInput id="isActive" text="Is Active?" onChange={onInputChange} isChecked={(editBeacon.isActive)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                error
                                    ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                    : <React.Fragment></React.Fragment>
                            }
                            <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                        </div>
                    </div>

                    {/* FINAL DO FORMULARIO */}

                </div>
            </div>

        </main>
        <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
}


export default EditBeacon;
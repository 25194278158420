import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';

import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import Pagination from '../../components/Pagination/Pagination';
import PontoRow from './PontoRow';
import { getPontos } from '../../services/PontosService';
import PontoModal from './PontoModal/PontoModal';
import Toast from '../../components/Toast/Toast';
import NewPontoButton from './NewPontoButton';

function Pontos() {

    const defaultLocation = useLocation();

    const { nome } = useParams();

    const [search, setSearch] = useState(nome || '');

    const [notification, setNotification] = useState({ type: '', text: '' });

    const history = useHistory();

    const [pontos, setPontos] = useState([]);

    const [editPonto, setEditPonto] = useState({});

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }


    const [count, setCount] = useState(0);

    const [page, setPage] = useState(parseInt(getPage()));



    function getPontosCall(token) {
        getPontos(search, page || 1, token)
            .then(result => {
                console.log(page);
                console.log(result.rows);

                setPontos(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }

    // paginacao
    useEffect(() => {
        const token = localStorage.getItem('token');

        getPontosCall(token);
    }, [page, search])

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history])


    // tratamento do formulario

    function onSearchChange(event) {
        setSearch(event.target.value);
        console.log(event.target.value);
    }

    function onNewClick(event) {
        setEditPonto(
            {
                nome: '',
                imagen: '',
                projetoId: 0,
                dotTypesId: 0,
                isActive: false
            })
        console.log('On New Click');
    }


    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        console.log('OnEditClick...');
        console.log(id);
        console.log(editPonto)
        setEditPonto(pontos.find(u => u.id == id));
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                {/* Cabeçalho */}
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Pontos</h1>
                    </div>
                    <div className="btn-toolbar mb-md-0">
                        <div className="d-inline-flex align-items-center">


                            <NewPontoButton onClick={onNewClick} />
                        </div>
                        <div className="ms-2 ms-lg-3">
                            {/* Campo para consulta */}

                            <SearchSymbol placeholder="Projeto" onChange={onSearchChange} />
                        </div>
                    </div>
                </div>
                {/* Corpo da página */}
                <div className="row">
                    <div className="col-12">

                        {/* Início da tabela */}
                        <div className="card card-body border-0 shadow table-wrapper table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th className="border-gray-200">View</th>
                                        <th className="border-gray-200">Código</th>
                                        <th className="border-gray-200">Projeto</th>
                                        <th className="border-gray-200">Tipo</th>
                                        <th className="border-gray-200">Nome</th>
                                        <th className="border-gray-200">Atualizado</th>
                                        <th className="border-gray-200">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pontos.map(pontos => (
                                            <PontoRow key={pontos.id} data={pontos} onEditClick={onEditClick} />
                                        ))
                                    }

                                </tbody>
                            </table>

                            <Pagination count={count} />
                        </div>
                        {/* FINAL DA TABELA */}


                    </div>
                </div>

                {/* Rodapé da pagina */}
                <Footer />
            </main>
            {/* Chama a tela modal */}
            <PontoModal data={editPonto} onSubmit={onModalSubmit} />
            {/* mensagen na tela */}
            <Toast type={notification.type} text={notification.text} />

        </React.Fragment>
    );
}

export default Pontos;
import React, { useState, useEffect } from 'react';
//import { getActiveProjetos, getAllProjetos } from '../../../services/ProjetosService';
import { getAllProjetos, getActiveProjetos } from '../../../services/ProjetosService';

/**
 * props:
 * - id
 * - onChange
 */
function SelectProjeto(props) {

    const [projetos, setProjetos] = useState(["LOADING"]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        let promise;
        if (props.id > 0) {
            //  promise = getAllProjetos();
            promise = getAllProjetos(token);

        } else {


            promise = getActiveProjetos(token);
        }

        promise.then(projetoObjects => {
            setProjetos(projetoObjects);
        })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setProjetos(['ERROR']);
            })
    }, [props.id])

    return (
        <select id="projetoId" className="form-select" value={props.id} onChange={props.onChange}>
            <option value="">Select...</option>
            {
                projetos && projetos.length > 0
                    ? projetos.map(projeto => (<option key={"projeto" + projeto.id} value={projeto.id}>{projeto.nome}</option>))
                    : <React.Fragment></React.Fragment>
            }
        </select>
    )
}

export default SelectProjeto;
